import {createTheme, DeprecatedThemeOptions} from '@mui/material/styles';
import {googleFonts} from '../googleFonts';
import palette from './palette';

export enum BreakpointNames {
    XS = 'xs',
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
    XL = 'xl',
}

export const themeOptions = {
    palette,
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    fontSize: '62.5%',
                    overflowY: 'scroll',
                },
                body: {
                    fontSize: '1.4rem',
                    lineHeight: 1.43,
                    letterSpacing: '0.01071em',
                },
                '.MuiAutocomplete-option[aria-selected="true"]': {
                    color: palette.text.contrast,
                    backgroundColor: `${palette.action.selected} !important`,
                },
                '.MuiAutocomplete-option .Mui-focused': {
                    color: palette.text.primary,
                },
                '.MuiMenuItem-root.Mui-selected, .MuiMenuItem-root.Mui-selected > .MuiTypography-root':
                    {
                        color: palette.text.primary,
                    },
            },
        },
        MuiPaper: {
            defaultProps: {
                square: true,
            },
            styleOverrides: {
                root: {
                    backgroundColor: palette.background.content,
                    backgroundImage: 'unset',
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: !!process.env.JEST_WORKER_ID,
            },
        },
        MuiButton: {
            defaultProps: {
                color: 'primary',
                variant: 'contained',
            },
            styleOverrides: {
                root: {
                    borderRadius: 2,
                    fontSize: '1.4rem',
                    color: palette.text.minContrast,
                    letterSpacing: '1.4px',
                    fontWeight: 700,
                    '&:focus': {
                        outline: `2px solid ${palette.action.active}`,
                    },
                },
                contained: {
                    color: palette.primary.light,
                },
                containedPrimary: {
                    boxShadow: '0 3px 5px 2px rgba(0,133,66,0.16)',
                    '&:focus': {
                        outline: `4px double ${palette.action.active}`,
                    },
                },
                outlined: {
                    color: palette.primary.main,
                },
                outlinedSecondary: {
                    color: palette.secondary.main,
                },
                text: {
                    fontWeight: 600,
                    textTransform: 'none',
                    letterSpacing: '1px',
                    color: palette.secondary.main,
                },
                textSecondary: {
                    color: palette.secondary.main,
                    letterSpacing: '.75px',
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                colorSecondary: {
                    color: palette.primary.main,
                    '&$checked': {
                        color: palette.primary.main,
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: '2px',
                    backgroundColor: '#e0e0e0',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                filled: {
                    color: palette.secondary.main,
                    fontSize: '1.4rem',
                    fontWeight: 600,
                    transform: 'translate(18px, 20px) scale(1)',
                    visibility: 'visible',
                    opacity: '1',
                    transition: 'visibility 0s, opacity 0.2s linear',
                    '&.MuiInputLabel-shrink': {
                        visibility: 'hidden',
                        opacity: '0',
                    },
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: palette.primary.light,
                    boxShadow: '12px 12px 24px 0 rgba(75,89,104,0.08)',
                    borderTopLeftRadius: '2px',
                    borderTopRightRadius: '2px',
                    borderRadius: '2px',
                    border: '1px solid transparent',
                    transition: '200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    '&:hover': {
                        backgroundColor: palette.primary.light,
                        border: '1px solid rgba(5,72,53,0.5)',
                        color: palette.secondary.dark,
                    },
                    '&.Mui-focused': {
                        backgroundColor: palette.primary.light,
                        color: palette.secondary.dark,
                        border: `1px solid ${palette.action.active}`,
                    },
                    '&.Mui-disabled': {
                        '&:hover': {
                            color: 'rgba(0, 0, 0, 0.38)',
                            border: '1px solid transparent',
                        },
                    },
                },
                input: {
                    padding: '18px',
                },
                underline: {
                    '&:before': {
                        borderBottom: 'none',
                    },
                    '&:after': {
                        borderBottom: 'none',
                    },
                    '&:hover': {
                        '&:before': {
                            borderBottom: 'none',
                        },
                    },
                    '&.Mui-disabled': {
                        '&:before': {
                            borderBottom: 'none !important',
                        },
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '1.3rem',
                    '&.Mui-focusVisible': {
                        color: palette.primary.light,
                    },
                    '&.Mui-selected': {
                        color: `${palette.text.contrast} !important`,
                        background: palette.action.selected,
                        '&.Mui-disabled': {
                            color: palette.primary.disabled,
                            background: 'unset',
                        },
                        '&:hover': {
                            background: palette.action.selected,
                        },
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    transition: 'transform 0.1s ease-in-out',
                    color: palette.primary.main,
                    cursor: 'pointer',
                    '&:hover': {
                        transform: 'translateY(1px)',
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: palette.primary.main,
                    '&$checked': {
                        color: palette.primary.main,
                    },
                },
                colorSecondary: {
                    '&$checked': {
                        color: palette.primary.main,
                    },
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    '& .MuiTableCell-head': {
                        fontWeight: 700,
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: palette.secondary.dark,
                    backgroundColor: palette.primary.light,
                    padding: '10px',
                    fontSize: '1.4rem',
                    boxShadow: '8px 8px 24px 0 rgba(67,87,92,0.16)',
                    fontWeight: 800,
                    borderRadius: '2px',
                    textAlign: 'left',
                },
                arrow: {
                    color: palette.primary.light,
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: palette.text.default,
                },
                h1: {
                    fontWeight: 900,
                    fontSize: '5rem',
                },
                h6: {
                    fontWeight: 'bold',
                },
                subtitle2: {
                    lineHeight: '2.4rem',
                },
                body2: {
                    fontSize: '1.2rem',
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected, &:hover': {
                        color: palette.primary.light,
                    },
                },
            },
        },
        MuiSkeleton: {
            styleOverrides: {
                wave: {
                    '&:after': {
                        background: `linear-gradient(90deg, transparent, ${palette.background.default}, transparent)`,
                    },
                },
            },
        },
    },
    componentVariables: {
        collapsedSideNav: {
            width: 56,
            zIndex: 1000,
        },
        expandedSideNav: {
            width: 230,
            zIndex: 1000,
        },
        topNav: {
            height: 56,
        },
        subPageHeader: {
            height: 54,
        },
    },
    breakpoints: {
        values: {
            [BreakpointNames.XS]: 0,
            [BreakpointNames.SM]: 600,
            [BreakpointNames.MD]: 960,
            [BreakpointNames.LG]: 1280,
            [BreakpointNames.XL]: 1920,
        },
    },
    typography: {
        fontFamily: googleFonts.map((font) => `"${font.name}"`).join(','),
        htmlFontSize: 10,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 400,
        fontWeightSemibold: 600,
        fontWeightBold: 700,
        fontWeightBlack: 900,
    },
    mobileToDesktopBreakpoint: 600,
};

const muiThemeOptions: DeprecatedThemeOptions = {
    ...(themeOptions as DeprecatedThemeOptions),
    spacing: 8,
};

export default createTheme(muiThemeOptions);
